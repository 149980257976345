import React from "react";
import Balancer from "react-wrap-balancer";
import FullProfilePic from "../../img/morgan-ramsey-full-profile.jpg";
import "./index.scss";

const BioComponent = () => {
  return (
    <div className="bio-container">
      <div className="bio-section">
        <div className="biography">
          <h3>About</h3>
          <p className="enlarged">
            <Balancer>
              Experienced designer passionate about intuitive user experiences.
            </Balancer>
          </p>
          <p>
            <Balancer>
              With 15+ years of experience in design and development, I've honed
              my skills with top companies such as iFit, Sony BMG, and Flywheel
              Energy.
            </Balancer>
          </p>
          <p>
            <Balancer>
              My focus on FrontEnd Engineering over the past 5 years has allowed
              me to excel at turning complex problems into easy user flows. I'm
              passionate about delivering clean projects that seamlessly blend
              design and code.
            </Balancer>
          </p>
          <p>
            <Balancer>
              Beyond work, I enjoy outdoor activities like camping, hiking, and
              hunting, as well as playing music to unwind.
            </Balancer>
          </p>
        </div>

        <div className="profile-image">
          <div className="image-cropper">
            <img src={FullProfilePic} alt="Morgan Ramsey" />
          </div>
          <span>Morgan Ramsey</span>
          UI / UX Designer
        </div>
      </div>
    </div>
  );
};

export default BioComponent;
