import React from "react";
import NavBar from "../../components/nav/index";

function FourOhFourPage() {
  return (
    <div className="404-page">
      <NavBar />
      You are here by accident....
    </div>
  );
}
export default FourOhFourPage;
