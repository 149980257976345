import ifitImage from "../../img/portfolio/ifit-onboarding.jpg";
import flywheelImage from "../../img/portfolio/flywheel-app.jpg";
import wegolookImage from "../../img/portfolio/wegolook-website.jpg";
import vhouseImage from "../../img/portfolio/vhouse-website.jpg";

export const Portfolio = [
  {
    id: "1",
    Name: "iFit",
    Description:
      "A subscription-based fitness company that also sells exercise equipment. I worked on the development for the onboarding flow for new users as well as the renewal of memberships for existing users. 🏋 ",
    Tags: ["FrontEnd Development", "UI Design", "B2C", "SAAS"],
    Behance: "https://www.behance.net/gallery/158929219/iFit-Onboarding-Flow",
    img: ifitImage,
    alt: "Onboarding flow for iFit",
  },
  {
    id: "2",
    Name: "Flywheel",
    Description:
      "An Oil & Gas company with a focus on responsible delivery of sustainable returns through operational excellence. I set up a design system and a reusable component system for their developers. I also reworked existing user journeys smoothing out pain points in the flows. 🚀",
    Tags: [
      "FrontEnd Development",
      "Design System",
      "UI/UX Design",
      "UX Research",
      "B2B",
    ],
    Behance: "https://www.behance.net/gallery/158931125/Flywheel-Energy",
    img: flywheelImage,
  },
  {
    id: "3",
    Name: "Valliant House",
    Description:
      "A men's and women's substance abuse treatment facility. I designed and developed their client-facing site as well as set up a design system for creating their multiple client forms.  👩‍⚕️",
    Tags: [
      "FrontEnd Development",
      "Design System",
      "UI/UX Design",
      "UX Research",
      "B2C",
    ],
    Behance: "https://www.behance.net/gallery/177720559/Valliant-House",
    img: vhouseImage,
    alt: "Client portal for ",
  },
  {
    id: "4",
    Name: "WeGoLook",
    Description:
      "WeGoLook helps businesses and individuals gather and validate information anytime, anywhere by sending an inspector for you. I designed a large portion of their app using material design guidelines. I also designed the customer-facing site as well as their client report layouts. 🔍",
    Tags: [
      "FrontEnd Development",
      "UI/UX Design",
      "UX Research",
      "Project Management",
      "B2B",
      "B2C",
    ],
    Behance: "https://www.behance.net/gallery/158931915/WeGoLook?",
    img: wegolookImage,
  },
];
